import React from 'react'
import Layout from '../components/layout'
import { Container } from 'reactstrap'

const About = () => (
  <Layout>
    <section className="py-5">
      <Container>
        <h1>About Us</h1>
        <h2>
          Greens Window Cleaning are a family company based in and around
          Chippenham.
        </h2>
        <p>
          Instead of just using a traditional sponge, bucket and a squeegee we
          have a complete pure water system that will keep your windows cleaner
          for longer. Our service is fully mobile and is focused at working to
          your convenience, so we can clean your windows when you are not
          available or when it best suits you. We also don’t use ladders so we
          can ensure that we will not trample any of your flowers or scratch any
          of your property.
        </p>
      </Container>
    </section>
    <section className="py-5 bg-primary text-light">
      <Container>
        <h2 className="text-light">Why choose Greens Window Cleaning?</h2>
        <h3 className="text-light">We work to your convenience</h3>
        <div className="d-flex justify-content-center">
          <ul>
            <li>Free estimates</li>
            <li>Postage payment envelope provided</li>
            <li>Text reminder on request</li>
            <li>Better for the environment</li>
            <li>Filtered water cleaning system</li>
            <li>Comprehensive window cleaning service</li>
            <li>Fully insured</li>
          </ul>
        </div>
      </Container>
    </section>
  </Layout>
)

export default About
